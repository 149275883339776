import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import 'dayjs/locale/en-gb';

const FranchisePersonalInformation = ({ formData, handleChangeForm, handleDateChangeForm }) => {
  const occupation = [
    {
      id: 1,
      name: "Private Job",
    },
    {
      id: 2,
      name: "Government Job",
    },
    {
      id: 3,
      name: "Business",
    },
    {
      id: 4,
      name: "Self Employed",
    },
  ];
  const gender = [
    {
      id: 1,
      name: "Male",
    },
    {
      id: 2,
      name: "Female",
    },
    {
      id: 3,
      name: "Other",
    },
  ];
  const maritalStatus = [
    {
      id: 1,
      name: "Single",
    },
    {
      id: 2,
      name: "Married",
    },
  ];
  const education = [
    {
      id: 1,
      name: "Below 12th",
    },
    {
      id: 2,
      name: "12th",
    },
    {
      id: 3,
      name: "Graduate",
    },
    {
      id: 4,
      name: "Post-Graduate",
    },
  ];
  const workExperience = [
    {
      id: 1,
      name: "0 - 1 Year",
    },
    {
      id: 2,
      name: "1 - 2 Year",
    },
    {
      id: 3,
      name: "2 - 3 Year",
    },
    {
      id: 4,
      name: "Above 3 Years",
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="firstName"
          name="firstName"
          value={formData?.firstName || ""}
          label="First name"
          fullWidth
          autoComplete="given-name"
          onChange={handleChangeForm}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="lastName"
          name="lastName"
          value={formData?.lastName || ""}
          label="Last name"
          fullWidth
          autoComplete="family-name"
          onChange={handleChangeForm}
        />
      </Grid>
      <Grid width="100%" item sx={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DatePicker
            label="Birth Date"
            name="birthDate"
            value={dayjs(formData.birthDate) || ""}
            sx={{ width: "100%" }}
            format="YYYY/MM/DD"
            onChange={(value) => handleDateChangeForm(value, "birthDate")}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="birthPlace"
          name="birthPlace"
          value={formData?.birthPlace || ""}
          label="Birth Place"
          fullWidth
          autoComplete="birth-place"
          onChange={handleChangeForm}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel required id="demo-simple-select-label-2">Gender</InputLabel>
          <Select
            id="gender"
            name="gender"
            value={formData?.gender || ""}
            label="Gender"
            fullWidth
            autoComplete="gender"
            onChange={handleChangeForm}
          >
            {gender.map((value) => (
              <MenuItem key={value.id} value={value.name}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>{" "}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel required id="demo-simple-select-label-2">
            Marital Status
          </InputLabel>
          <Select
            id="marital-status"
            name="maritalStatus"
            value={formData?.maritalStatus || ""}
            label="Marital Status"
            fullWidth
            autoComplete="single"
            onChange={handleChangeForm}
          >
            {maritalStatus.map((value) => (
              <MenuItem key={value.id} value={value.name}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>{" "}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel required id="demo-simple-select-label-2">Education</InputLabel>
          <Select
            labelId="demo-simple-select-label-2"
            id="demo-simple-select-2"
            label="Education"
            name="education"
            value={formData?.education || ""}
            onChange={handleChangeForm}
          >
            {education.map((value) => (
              <MenuItem key={value.id} value={value.name}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>{" "}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel required id="demo-simple-select-label-1">Occupation</InputLabel>
          <Select
            labelId="demo-simple-select-label-1"
            id="demo-simple-select-1"
            label="Occupation"
            name="occupation"
            value={formData?.occupation || ""}
            onChange={handleChangeForm}
          >
            {occupation.map((value) => (
              <MenuItem key={value.id} value={value.name}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>{" "}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="occupation-type"
          name="occupationType"
          value={formData?.occupationType || ""}
          label="Occupation Type"
          fullWidth
          autoComplete="occupation-type"
          onChange={handleChangeForm}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel required id="demo-simple-select-label-8">
            Work Experience
          </InputLabel>
          <Select
            labelId="demo-simple-select-label-8"
            id="demo-simple-select-8"
            label="Work Experience"
            name="workExperience"
            value={formData?.workExperience || ""}
            onChange={handleChangeForm}
          >
            {workExperience.map((value) => (
              <MenuItem key={value.id} value={value.name}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>{" "}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="email"
          name="email"
          value={formData?.email || ""}
          label="Email"
          fullWidth
          autoComplete="example@gmail.com"
          onChange={handleChangeForm}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="phone"
          name="phone"
          value={formData?.phone || ""}
          label="Phone"
          fullWidth
          autoComplete="+919999999999"
          onChange={handleChangeForm}
        />
      </Grid>
    </Grid>
  );
};

export default FranchisePersonalInformation;
