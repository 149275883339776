import { Box, Typography } from "@mui/material";
import React from "react";

const BrandsVision = () => {
  return (
    <Box>
      <Box className="content" m="2vw">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            fontWeight={500}
            variant="h4"
            style={{ fontSize: "4rem", padding: "6px", borderRadius: "8px" }}
            className="title-color"
            gutterBottom
          >
            Overview
          </Typography>
        </Box>
        <p>
          At <strong>Chatpata</strong>, we're not just selling food; we're
          creating tasty experiences. Our vision is more than just a brand; it's
          about being a spot for awesome taste, cool ideas, and sharing great
          moments over fantastic food.
        </p>
        <p>
          Let us give you the inside scoop on why we crafted our menu the way we
          did. We aimed to create a culinary symphony that resonates with the
          vibrant and diverse tastes of today's generation. From the hearty
          satisfaction of Juicy Burgers and Sandwiches to the shared joy of
          Pizza choices for group gatherings, every item serves a purpose.
        </p>
        <p>
          Our Cold Coffees and Fruity Mocktails cater to the dynamic lifestyle
          of the youth—keeping them energized and refreshed. And of course, the
          Creamy Thick Shakes and Milkshakes? They're the sweet crescendo,
          ensuring every meal ends on a high note.
        </p>
        <p>
          This menu isn't just a list of dishes; it's a carefully curated
          experience that aligns with the preferences and lifestyles of our
          target audience. It's about offering not just food but a memorable
          journey that resonates with the spirit of today's generation. Ready to
          bring this flavor-packed experience to your franchise? Let's make it
          happen!
        </p>
        <p>
          As a franchisee, you're not just running a store; you're the conductor
          of taste in your community. We see your outlet as a place to mix in
          creativity, top-notch quality, and a bit of uniqueness in every dish.
        </p>
      </Box>
      {/* 
      <Box className="content" m="2vw">
        <Typography fontWeight={500} variant="h5" gutterBottom>
          Our Brand Overture:
        </Typography>
        <p>
          Chatpata, where flavour is not just a sensation; it's a spectacular
          performance. We're not your ordinary eatery; we're the conductors of a
          taste orchestra, blending creativity, quality, and a pinch of
          eccentricity in every dish.{" "}
        </p>
      </Box>
      <Box className="content" m={3}>
        <Typography fontWeight={500} variant="h5" gutterBottom>
          Vision Symphony:
        </Typography>
        <p style={{ textAlign: "center" }}>
          Picture a franchise network harmonizing flavours across the globe,
          each outlet a unique note in our flavourful symphony. Our vision is a
          world where Chatpata isn't just a brand; it's a global celebration of
          taste, innovation, and the joy of sharing memorable moments over
          extraordinary food.
        </p>
      </Box> */}
      <Box className="content" m={3} sx={{ textAlign: "center" }}>
        <Typography
          fontWeight={500}
          className="mouse-memoirs fs-28"
          variant="p"
          gutterBottom
        >
          Ready to compose your success story with us?
        </Typography>
        <Typography
          mt="14px"
          fontWeight={500}
          variant="h4"
          className="title-color"
          gutterBottom
        >
          Let's Go
        </Typography>
      </Box>
    </Box>
  );
};

export default BrandsVision;
