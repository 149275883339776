import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const SideBar = ({ options, isActivePage, getLink }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {options.map((value, index) => (
        <Link
          key={index}
          style={{
            color: isActivePage(value) ? "rgb(234, 90, 38)" : "inherit",
          }}
          to={`${getLink(value)}`}
        >
          <Typography variant="h6"  >
            {value}
          </Typography>
        </Link>
      ))}
    </Box>
  );
};

export default SideBar;
