import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Franchise = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageValue, setPageValue] = useState("brand's-vision");
  const options = [
    "Overview",
    // "Product Basket",
    "Chatpata Advantage",
    "Franchise Store Model",
    "Franchise Roadmap",
    "FAQs",
    "Apply Here",
  ];

  const isActivePage = (page) => {
    return (
      page.toLowerCase().replaceAll(" ", "-") ===
      location.pathname.split("/")[2]
    );
  };

  const getLink = (value) => value.toLowerCase().replaceAll(" ", "-");

  const onPageChange = (e) => {
    e.preventDefault();
    navigate(`${e.target.value}`);
  };

  useEffect(() => {
    if (!location.pathname.split("/")[2]) {
      navigate("/own-a-franchise/overview");
    }
    setPageValue(location.pathname.split("/")[2]);
  }, [location.pathname]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "80vh",
      }}
      my="2vh"
      className="main-background-image"
    >
      <Box sx={{ display: { xs: "none", md: "block" }, width: "100%" }}>
        {/* <Box sx={{ background: "black", textAlign: "center", color: "white" }}>
          <Typography fontWeight={500} variant="h5" gutterBottom>
            Call or whatsapp on this number -{" "}
            <span style={{ color: "yellow", font: "inherit" }}>
              +91 99999 99999
            </span>
          </Typography>
        </Box> */}
        <Container sx={{ display: "flex", mt: "2rem" }}>
          <Box p={3} sx={{ width: "25%" }}>
            <SideBar
              options={options}
              isActivePage={isActivePage}
              getLink={getLink}
            />
          </Box>
          <Box
            p={3}
            mb={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "75%",
              // height: "70vh",
              border: "5px solid black",
              borderRadius: "10px",
            }}
          >
            <Outlet />
          </Box>
        </Container>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        {/* <Box sx={{ background: "black", textAlign: "center", color: "white" }}>
          <Typography fontWeight={500} variant="h5" mb={0} gutterBottom>
            Call or whatsapp on this number
          </Typography>
          <Typography fontWeight={500} variant="h5" gutterBottom>
            <span style={{ color: "yellow", font: "inherit" }}>
              +91 99999 99999
            </span>
          </Typography>

        </Box> */}
        <Box p={3}>
          <Box my={2} width="100%" display="flex" justifyContent="center">
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="demo-simple-select-label">Page</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={pageValue || ""}
                label="Page"
                onChange={onPageChange}
              >
                {options.map((value, index) => (
                  <MenuItem key={index} value={getLink(value)}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Franchise;
