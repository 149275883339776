import { Box } from "@mui/material";
import React from "react";
import { getImageLink, preloadImages } from "../common/functions";

const RoadMap = () => {
  const imageUrls = [
    getImageLink("/assets/roadmap.jpg"),
  ];
  preloadImages(imageUrls);

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center", border: "3px solid", borderRadius: "5px" }}
    >
      <img style={{ width: "100%" }} src={getImageLink("/assets/roadmap.jpg")} alt="roadmap" />
    </Box>
  );
};

export default RoadMap;
