import { mkConfig, generateCsv, download } from "export-to-csv"; //or use your library of choice here
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import dayjs from 'dayjs';

export const camelCaseToWords = (s) => {
    const result = s.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export const getImageLink = (path) => {
    // check and use local when in development mode
    return (process.env.REACT_APP_IMAGE_DOMAIN || "") + path;
}

export const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
    });
};


export const handleExportCSV = (data, filename) => {
    const csvConfig = mkConfig({
        fieldSeparator: ",",
        decimalSeparator: ".",
        useKeysAsHeaders: true,
        filename: `${filename}_${new Date().toISOString()}.csv`,
    });
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
};

export const handleExportExcel = (data = [], filename) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const wscols = data.length ? Object.values(data?.[0]).map(i => ({ wch: 20 })) : []
    const ws = XLSX.utils.json_to_sheet(data);
    ws['!cols'] = wscols;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blobData = new Blob([excelBuffer], {
        type: fileType,
    });
    FileSaver.saveAs(blobData, `${filename}_${new Date().toISOString()}.xlsx`);
};

export const convertToLocalTimeZone = (iso) => {
    let date = iso || new Date();
    return dayjs(date)
}
