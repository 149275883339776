import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { getImageLink, preloadImages } from "../common/functions";

const FranchiseModels = () => {
  const navigate = useNavigate();
  const imageUrls = [
    getImageLink("/assets/models/Food.jpg"),
    getImageLink("/assets/models/6.jpg"),
    getImageLink("/assets/models/4.jpg"),
    getImageLink("/assets/models/3.jpg"),
    getImageLink("/assets/models/5.jpg"),
    getImageLink("/assets/models/2.jpg"),
    getImageLink("/assets/models/1.jpg"),
  ];
  preloadImages(imageUrls);

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box>
        <Grid container spacing={10}>
          <Grid className="content text-center" item xs={12}>
            <Typography
              fontWeight={500}
              variant="h5"
              className="title-color"
              gutterBottom
            >
              Chatpata Franchise Store
            </Typography>
            <img
              style={{
                border: "1px solid black",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "1px 1px 4px 1px gray",
                maxWidth: "400px",
                width: "70vw",
              }}
              src={getImageLink("/assets/models/Food.jpg")}
              alt="food express"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} my={3}>
          <Grid
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
            item
            xs={12}
            md={6}
          >
            <img
              width="100px"
              src={getImageLink("/assets/models/6.jpg")}
              alt="store"
            />
            <Box>
              <Typography className="title-color">Area Required</Typography>
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                Minimum 200 - 400 sq feet
              </p>
            </Box>
          </Grid>
          <Grid
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
            item
            xs={12}
            md={6}
          >
            <img
              width="100px"
              src={getImageLink("/assets/models/4.jpg")}
              alt="store"
            />
            <Box>
              <Typography className="title-color">Total Investment</Typography>
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                Min. INR 15+ lacs
              </p>
              <p style={{ fontSize: "16px" }}>(Depends on store size)</p>
            </Box>
          </Grid>
          <Grid
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
            item
            xs={12}
            md={6}
          >
            <img
              width="100px"
              src={getImageLink("/assets/models/3.jpg")}
              alt="store"
            />
            <Box>
              <Typography className="title-color">Franchise Fees</Typography>
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                INR 5 lacs + GST
              </p>
              <p style={{ fontSize: "16px" }}>
                (Not Included in Total Investment)
              </p>
            </Box>
          </Grid>
          <Grid
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
            item
            xs={12}
            md={6}
          >
            <img
              width="100px"
              src={getImageLink("/assets/models/5.jpg")}
              alt="store"
            />
            <Box>
              <Typography className="title-color">Average Payback</Typography>
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                Approx. 18 to 24 Months*
              </p>
            </Box>
          </Grid>
          <Grid
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
            item
            xs={12}
            md={6}
          >
            <img
              width="100px"
              src={getImageLink("/assets/models/2.jpg")}
              alt="store"
            />
            <Box>
              <Typography className="title-color">Mode of Operation</Typography>
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>FOFO</p>
            </Box>
          </Grid>
          <Grid
            sx={{ display: "flex", gap: 1, alignItems: "center" }}
            item
            xs={12}
            md={6}
          >
            <img
              width="100px"
              src={getImageLink("/assets/models/1.jpg")}
              alt="store"
            />
            <Box>
              <Typography className="title-color">Term</Typography>
              <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                5 Years Agreement
              </p>
              <p style={{ fontSize: "16px" }}>(Renewable)</p>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{ fontSize: "20px", textAlign: "center", fontWeight: 500, mt: 4 }}
        >
          For more information, please{" "}
          <Link style={{ color: "blue" }} to="/own-a-franchise/faqs">
            click here
          </Link>
        </Box>
        <Box display="flex" justifyContent="center" my={3}>
          <Button
            variant="contained"
            onClick={() =>
              navigate("/own-a-franchise/apply-here#franchise-form")
            }
          >
            Apply Here
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FranchiseModels;
