import { Box } from "@mui/material";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", maxHeight: "70vh" }} id="error__page">
            <Box sx={{ textAlign: 'center' }}>
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
            </Box>
        </Box>
    );
}