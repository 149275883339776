import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import "./CustomCard.css"

const CustomCard = ({ title, description }) => {
    return (
        <Box sx={{ maxWidth: "450px", height: "100%", mb: 2 }}>
            <CardActionArea sx={{ height: "100%" }} >
                <Card sx={{ display: 'flex', flexWrap: 'wrap-reverse', height: "100%", justifyContent: 'center' }}>
                    <CardContent sx={{ width: "250px" }}>
                        <Typography component="h2" variant="h4">
                            {title}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            {description}
                        </Typography>
                    </CardContent>
                    <CardMedia
                        component="img"
                        sx={{ width: "138px", objectFit: 'contain', p: 1 }}
                        image={"/assets/" + title.toLowerCase().replaceAll(' ', '-') + ".png"}
                        alt={"logo"}
                    />
                </Card>
            </CardActionArea>
        </Box>
    )
}

export default CustomCard