import React from "react";
import { Box, Grid } from "@mui/material";
import HomeSwipper from "../components/HomeSwipper";
import { useNavigate } from "react-router-dom";
import { getImageLink, preloadImages } from "../common/functions";

const Home = () => {
//   const navigate = useNavigate();
//   const imageUrls = [
//     getImageLink("/assets/zomato.svg"),
//     getImageLink("/assets/swiggy.svg"),
//   ];
//   preloadImages(imageUrls);

  return (
    <Box py={3} sx={{ background: "none" }}>
      <HomeSwipper />
      {/* <Box py={5} display="flex" justifyContent="center">
        <Grid p={5} maxWidth="800px" container spacing={4}>
          <Grid item display="flex" justifyContent="center" xs={12} md={6}>
            <img
              width="100%"
              style={{ maxWidth: "320px", cursor: "pointer" }}
              onClick={() => navigate("/")}
              src={getImageLink("/assets/zomato.svg")}
              alt="zomato"
            />
          </Grid>
          <Grid item display="flex" justifyContent="center" xs={12} md={6}>
            <img
              width="100%"
              style={{ maxWidth: "320px", cursor: "pointer" }}
              onClick={() => navigate("/")}
              src={getImageLink("/assets/swiggy.svg")}
              alt="swiggy"
            />
          </Grid>
        </Grid>
      </Box> */}
    </Box>
  );
};

export default Home;
