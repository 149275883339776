import { Box, Typography, Button } from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useOutletContext } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  convertToLocalTimeZone,
  handleExportCSV,
  handleExportExcel,
} from "../common/functions";
import DateRangeDataFilter from "../components/DateRangeDataFilter";

const FranchiseList = () => {
  const { allData } = useOutletContext();
  const [data, setData] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const filename = `Franchise`;

  useEffect(() => {
    if (allData?.franchise) {
      const parsedData = allData.franchise.map((value) => ({
        ...value,
        birthDate: value?.birthDate?.split("T")?.[0] || "",
        created_at: convertToLocalTimeZone(value?.created_at).format(
          "YYYY-MM-DD"
        ),
        isVisited: value.isVisited ? "Yes" : "No",
        leaveCurrentProfession: value.leaveCurrentProfession ? "Yes" : "No",
        anyFranchisePartner: value.anyFranchisePartner ? "Yes" : "No",
      }));
      setParsedData(parsedData);
      setData(parsedData);
    }
  }, [allData]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at",
        header: "Created At",
        size: 150,
      },
      {
        accessorKey: "firstName", //access nested data with dot notation
        header: "First Name",
        size: 150,
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
        size: 150,
      },
      {
        accessorKey: "birthDate",
        header: "Birth Date",
        size: 150,
      },
      {
        accessorKey: "birthPlace",
        header: "Birth Place",
        size: 150,
      },
      {
        accessorKey: "gender",
        header: "Gender",
        size: 150,
      },
      {
        accessorKey: "maritalStatus",
        header: "Marital Status",
        size: 150,
      },
      {
        accessorKey: "education",
        header: "Education",
        size: 150,
      },
      {
        accessorKey: "occupation",
        header: "Occupation",
        size: 150,
      },
      {
        accessorKey: "occupationType",
        header: "Occupation Type",
        size: 150,
      },
      {
        accessorKey: "workExperience",
        header: "Work Experience",
        size: 150,
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        size: 200,
      },
      {
        accessorKey: "phone", //normal accessorKey
        header: "Phone",
        size: 200,
      },
      {
        accessorKey: "city",
        header: "City",
        size: 150,
      },
      {
        accessorKey: "state",
        header: "State",
        size: 150,
      },
      {
        accessorKey: "areaPreference1",
        header: "Area Preference 1",
        size: 150,
      },
      {
        accessorKey: "areaPreference2",
        header: "Area Preference 2",
        size: 150,
      },
      {
        accessorKey: "isVisited",
        header: "Is Visited",
        size: 150,
      },
      {
        accessorKey: "leaveCurrentProfession",
        header: "Leave Current Profession",
        size: 150,
      },
      {
        accessorKey: "anyFranchisePartner",
        header: "Any Franchise Partner",
        size: 150,
      },
      {
        accessorKey: "timeGiven",
        header: "Time Given",
        size: 150,
      },
      {
        accessorKey: "relationship",
        header: "Relationship",
        size: 150,
      },
      {
        accessorKey: "propertyType",
        header: "Property Type",
        size: 150,
      },
      {
        accessorKey: "periodOfOpening",
        header: "Period Of Opening",
        size: 150,
      },
      {
        accessorKey: "explainYourself",
        header: "Explain Yourself",
        size: 150,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: { density: "compact" },
    enableRowNumbers: true,
    rowNumberDisplayMode: "original", // default
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportCSV(data, filename)}
          startIcon={<FileDownloadIcon />}
        >
          Export CSV
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() => handleExportExcel(data, filename)}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>
      </Box>
    ),
  });

  return (
    <Box
      my={2}
      mx="5vw"
      className="about center-main main-background-image"
      sx={{ display: { xs: "flex" }, flexDirection: "column" }}
    >
      <Box className="content" m="2vw">
        <Typography
          className="title-color"
          fontWeight={500}
          variant="h4"
          gutterBottom
        >
          Franchise
        </Typography>
      </Box>
      <DateRangeDataFilter data={parsedData} setData={setData} />
      <Box width="100%">
        <MaterialReactTable table={table} />
      </Box>
    </Box>
  );
};

export default FranchiseList;
