import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from 'react'
import { useWindowSize } from "@uidotdev/usehooks";
import Title from "../components/Title";
import CustomCard from "../components/CustomCard";
import { getImageLink } from "../common/functions";

const Vision = () => {
  const size = useWindowSize();

  const visionCards = [
    {
      title: "Quality Ingredients",
      description: `Sourcing the ﬁnest, locally-sourced ingredients to create delicious and 
      nutrious menu options.`,
    },
    {
      title: "Delivering Exceptional Taste",
      description: `We strive to provide our customers with a diverse range of 
      mouthwatering menu options cra ed from the ﬁnest ingredients, always exceeding their 
      culinary expectations.`,
    },
    {
      title: "Prompt Service",
      description: `We are dedicated to offering quick and efficient service without
      compromising on quality and hygiene, ensuring our customers' valuable time is well spent.`,
    },
    {
      title: "Innovation",
      description: `We embrace innovation in our menu offerings, technology integration, and service
      models to stay at the forefront of the QSR industry.`,
    },
    {
      title: "Community Engagement",
      description: `We actively engage with and support the communities we serve by
      sourcing locally, providing employment opportunities, and participating in initiatives that
      enhance the well-being of our neighbours.`,
    },
    {
      title: "Environmental Responsibility",
      description: `We are committed to minimizing our environmental footprint
      by implementing sustainable practices in our operations, from sourcing to waste management.`,
    },
    {
      title: "Employee Development",
      description: `We nurture a culture that encourages personal and professional
      growth for our team members, creating an atmosphere of respect, teamwork, and innovation.`,
    },
    {
      title: "Customer Satisfaction",
      description: `We listen to our customers' feedback and continuously adapt to their
      preferences, striving to make every visit an exceptional experience.`,
    },
    {
      title: "Profitable Growth",
      description: `We aim for sustainable and profitable growth, ensuring the longevity and
      success of our QSR chain while providing returns to our stakeholders.`,
    },
  ];
  return (
    <Box>
      <Box>
        <img
          style={{ width: "100%", maxHeight: "70vh", marginBottom: "2rem" }}
          src={getImageLink(size.width >= 768 ? "/assets/vision.jpg": "/assets/vision2.jpg")}
          alt="fries"
        />
      </Box>
      <Box>
        <Title variant="h3">Mission</Title>
        <Container>
          <Title sx={{ marginBottom: "0" }}>
            At "Chatpata: Khao - Piyo - Aish Karo…", we are dedicated to
            delivering fast, ﬂavourful, and fresh dining experiences to our
            valued customers.{" "}
          </Title>
          <Title>We achieve this by:</Title>
          <Box className="custom__cards">
            {visionCards.map((card, key) => (
              <CustomCard key={key} {...card} />
            ))}
          </Box>``
          <Title mt={5}>
            By living up to our mission, we aim to realize our vision and create
            a world where great food, convenience, and community engagement come
            together, leaving a lasting, positive impact on our customers,
            employees, and the environment."{" "}
          </Title>
        </Container>
      </Box>
    </Box>
  );
};

export default Vision;
