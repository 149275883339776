import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import cities from "../common/gujarat-cities.json";
import Swal from "sweetalert2";
import {
  camelCaseToWords,
  getImageLink,
  preloadImages,
} from "../common/functions";
import { propertyPartner } from "../api";

const PropertyPartnership = () => {
  const imageUrls = [getImageLink("/assets/property-partners.jpg")];
  preloadImages(imageUrls);

  const defaultFormData = {
    state: "Gujarat",
  };

  const [formData, setFormData] = useState(defaultFormData);

  const handleChangeForm = (e, p = null, typeConversion = null) => {
    const validPhone = /^\d{0,10}$/;

    const name = e.target.name;
    const value = typeConversion
      ? typeConversion(e.target.value)
      : e.target.value;
    if (name === "phone" && !value.match(validPhone)) {
      return;
    }
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };

  const validateAttributes = () => {
    const attributes = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "city",
      "state",
      "area",
      "rent",
      "ownership",
      "propertyType",
    ];
    let isNotCheckAll = false;
    const validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const validPhone = /^\d{10}$/;
    for (const value of attributes) {
      if (!formData[value]) {
        isNotCheckAll = value;
        break;
      } else if (value === "email" && !formData[value].match(validEmailRegex)) {
        isNotCheckAll = value;
        break;
      } else if (value === "phone" && !formData[value].match(validPhone)) {
        isNotCheckAll = value;
        break;
      }
    }
    if (isNotCheckAll) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${camelCaseToWords(isNotCheckAll)} is Missing`,
      });
    }
    return !isNotCheckAll;
  };

  const handleSubmit = async () => {
    if (validateAttributes()) {
      propertyPartner
        .create(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Congrats!",
            text: `Your Property Partnership Form Submitted`,
          }).then((res) => setFormData(defaultFormData));
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err?.response?.data?.message || `Error While Submitting Data`,
          });
        });
    }
  };

  const ownership = [
    {
      id: 1,
      name: "Builder",
    },
    {
      id: 2,
      name: "Agent",
    },
    {
      id: 3,
      name: "Self Owned",
    },
  ];
  const property = [
    {
      id: 1,
      name: "Local Market Area",
    },
    {
      id: 2,
      name: "Shopping Complex / Mall",
    },
    {
      id: 3,
      name: "Main Road",
    },
    {
      id: 4,
      name: "Cross Road (Junction)",
    },
    {
      id: 5,
      name: "Near School/College",
    },
    {
      id: 6,
      name: "Other",
    },
  ];

  return (
    <Box
      my={5}
      mx="5vw"
      className="center-main property-partnership main-background-image"
    >
      <Box className="content" m="2vw">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={getImageLink("/assets/property-partners.jpg")}
            style={{ width: "9rem" }}
            alt="property-partners"
          />
          <Typography
            className="title-color"
            fontWeight={500}
            variant="h4"
            gutterBottom
          >
            Be our Property Partners
          </Typography>
        </Box>
        <p>
          Do you own a property in a prime spot like the main market, mall, or
          highway that's up for lease?
        </p>
        <p>
          Well, your search ends here! <strong>"Chatpata"</strong> is on the
          expansion spree, actively seeking properties with high foot traffic
          that match our brand specifications.
        </p>
        <p>
          Your property might just be the ideal addition to our ever-growing
          network!{" "}
        </p>
        <p
          className="text-center"
          style={{ fontSize: "1.4rem", textAlign: "center" }}
        >
          <strong> :: Please fill out the details below :: </strong>
        </p>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              value={formData?.firstName || ""}
              onChange={handleChangeForm}
              label="First name"
              fullWidth
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              value={formData?.lastName || ""}
              onChange={handleChangeForm}
              label="Last name"
              fullWidth
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              name="email"
              value={formData?.email || ""}
              onChange={handleChangeForm}
              label="Email"
              fullWidth
              autoComplete="example@gmail.com"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="phone"
              name="phone"
              value={formData?.phone || ""}
              onChange={handleChangeForm}
              label="Phone"
              fullWidth
              autoComplete="+919999999999"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel required id="city">
                City
              </InputLabel>
              <Select
                labelId="city"
                required
                id="city"
                name="city"
                value={formData?.city || ""}
                onChange={handleChangeForm}
                label="City"
              >
                {cities.map((value) => (
                  <MenuItem key={value.id} value={value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>{" "}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="state"
              name="state"
              value={formData?.state || "Gujarat"}
              // onChange={handleChangeForm}
              label="State/Province/Region"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="area"
              name="area"
              value={formData?.area || ""}
              onChange={handleChangeForm}
              label="Area"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="rent"
              name="rent"
              value={formData?.rent || ""}
              onChange={handleChangeForm}
              label="Rent (Monthly approx.)"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="location"
              name="location"
              value={formData?.location || ""}
              onChange={handleChangeForm}
              label="Copy and paste the property's Google location link here."
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel required id="city">
                Ownership
              </InputLabel>
              <Select
                labelId="ownership"
                id="ownership"
                required
                name="ownership"
                value={formData?.ownership || ""}
                onChange={handleChangeForm}
                label="Ownership"
              >
                {ownership.map((value) => (
                  <MenuItem key={value.id} value={value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>{" "}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel required id="propertyType">
                Property Type
              </InputLabel>
              <Select
                labelId="propertyType"
                id="property"
                required
                name="propertyType"
                value={formData?.propertyType || ""}
                onChange={handleChangeForm}
                label="Property Type"
              >
                {property.map((value) => (
                  <MenuItem key={value.id} value={value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>{" "}
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="desc"
              name="propertyDescription"
              value={formData?.propertyDescription || ""}
              onChange={handleChangeForm}
              label="Property Description"
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2 }}
            >
              Click To Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PropertyPartnership;
