import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import cities from "../common/gujarat-cities.json";
import ApplyForm from "./ApplyForm";

const HowToContact = () => {

  return (
    <Box className="apply-here">
      <Box className="content" m={3}>
        <p>
          Thank you very much for your interest in Franchise of{" "}
          <strong>Chatpata : Khao - Piyo - Aish Karo!</strong> We are among one
          of the fastest-growing QSR chains.
        </p>
        <p>
          <strong>Imagine being part of Team Chatpata—exciting, right?</strong>
        </p>
        <p>
          For this, we require your information, which is essential for us to
          understand you better and decide if we're a match made in franchise
          business relations.
        </p>
        <p>
          Your Information will help us to develope franchisee-friendly systems
          and processes to make your franchise store successful and profitable.
        </p>
        <p>
          <strong>
            Kindly, take few minutes to answer the questions with the real deal.
            Please, stay honest, as any misrepresentation might affect your
            chance at getting that Franchise Allocation.
          </strong>
        </p>
        <Typography
          mt="14px"
          textAlign="center"
          fontWeight={500}
          variant="h5"
          className="title-color"
          gutterBottom
        >
          Excited you joined the Chatpata team!!
        </Typography>

        <p style={{ fontSize: "1.4rem", textAlign: "center" }}>
          <strong> :: Please fill out the details below :: </strong>
        </p>
        <ApplyForm />
      </Box>
    </Box>
  );
};

export default HowToContact;
