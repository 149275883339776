import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import FranchiseRelatedInformation from "./FranchiseRelatedInformation";
import FranchisePersonalInformation from "./FranchisePersonalInformation";
import { Grid, Typography } from "@mui/material";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { camelCaseToWords } from "../common/functions";
import { franchise } from "../api";

export default function ApplyForm() {
  const [activeStep, setActiveStep] = useState(0);
  const defaultFormData = {
    birthDate: dayjs().format("YYYY-MM-DD"),
    state: "Gujarat",
  };
  const [formData, setFormData] = useState(defaultFormData);

  const handleChangeForm = (e, p = null, typeConversion = null) => {
    const validPhone = /^\d{0,10}$/;

    const name = e.target.name;
    const value = typeConversion
      ? typeConversion(e.target.value)
      : e.target.value;
    if (name === "phone" && !value.match(validPhone)) {
      return;
    }
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };

  const handleDateChangeForm = (date, name) => {
    const value = date.format("YYYY-MM-DD");
    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };
  useEffect(() => {
    console.log("form", formData);
  }, [formData])
  

  const steps = ["Personal", "Franchise Related"];
  const MAX_STEPS = 1;

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <FranchisePersonalInformation
            formData={formData}
            handleChangeForm={handleChangeForm}
            handleDateChangeForm={handleDateChangeForm}
          />
        );
      case 1:
        return (
          <FranchiseRelatedInformation
            formData={formData}
            handleChangeForm={handleChangeForm}
            handleDateChangeForm={handleDateChangeForm}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const validateAttributes = () => {
    const attributes = {
      0: [
        "firstName",
        "lastName",
        "birthDate",
        "birthPlace",
        "gender",
        "maritalStatus",
        "education",
        "occupation",
        "occupationType",
        "workExperience",
        "email",
        "phone",
      ],
      1: [
        "city",
        "state",
        "areaPreference1",
        "areaPreference2",
        "isVisited",
        "leaveCurrentProfession",
        "anyFranchisePartner",
        "timeGiven",
        "relationship",
        "propertyType",
        "periodOfOpening",
        "explainYourself",
      ],
    };
    let isNotCheckAll = false;
    const validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const validPhone = /^\d{10}$/;
    for (const value of attributes[activeStep]) {
      if ((value === "isVisited" || value === "leaveCurrentProfession" || value === "anyFranchisePartner") && typeof formData[value] === "boolean") {
        continue;
      }
      if (!formData[value]) {
        isNotCheckAll = value;
        break;
      } else if (value === "email" && !formData[value].match(validEmailRegex)) {
        isNotCheckAll = value;
        break;
      } else if (value === "phone" && !formData[value].match(validPhone)) {
        isNotCheckAll = value;
        break;
      }
    }
    if (isNotCheckAll) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${camelCaseToWords(isNotCheckAll)} is Missing`,
      });
    }
    return !isNotCheckAll;
  };

  const handleNext = async () => {
    if (validateAttributes()) {
      if (activeStep === MAX_STEPS) {
        franchise
        .create(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Congrats!",
            text: `Your Franchise Form Submitted`,
          }).then((res) => {
          });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setFormData(defaultFormData);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err?.response?.data?.message || `Error While Submitting Data`,
          });
        });
      }else{
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <React.Fragment>
      <Paper
        id="franchise-form"
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Grid item xs={12}>
              <p>
                Thanks a lot and big shoutout for completing the form! Your
                effort means a lot to Team Chatpata.
              </p>
              <p>
                Quick heads-up: We're trusting that the info you shared is true
                to your knowledge. The Chatpata will use it to check if you're
                the right fit for an Chatpata Franchisee.
              </p>
              <Typography
                mt="14px"
                textAlign="center"
                fontWeight={500}
                variant="h5"
                className="title-color"
                gutterBottom
              >
                Appreciate your honesty and time!
              </Typography>
              <p className="text-center" style={{ fontWeight: "bold" }}>
                Chatpata's Franchise Team will contact you soon...
              </p>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getStepContent(activeStep)}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 3, ml: 1 }}
              >
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Paper>
    </React.Fragment>
  );
}
