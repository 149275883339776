import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { feedback, franchise, initializeToken, propertyPartner } from "../api";
import AdminHeader from "../components/AdminHeader";
import adminTheme from "../common/adminTheme";

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [allData, setAllData] = useState(undefined);

  useEffect(() => {
    if (!token) navigate("/login");
    initializeToken(token);
    const allAPIPromise = [
      franchise.get(),
      propertyPartner.get(),
      feedback.get(),
    ];
    Promise.all(allAPIPromise)
      .then((res) => {
        const data = {
          franchise: res[0]?.data?.data || [],
          propertyPartner: res[1]?.data?.data || [],
          feedback: res[2]?.data?.data || [],
        };
        setAllData(data);
      })
      .catch((err) => {
        console.log("error while fetching data", err);
        navigate("/login");
      });
  }, []);

  return (
    <Box>
      <AdminHeader />
      <ThemeProvider theme={adminTheme}>
        <Outlet context={{ token, allData }} />
      </ThemeProvider>
    </Box>
  );
};

export default ProtectedRoute;
