import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { MdMenu } from "react-icons/md";
import HideOnScroll from "./HideOnScroll";
import { APP_NAME } from "../common/constant";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getImageLink, preloadImages } from "../common/functions";

// const pages = ['Menu', 'Stores', 'About Us', 'Contact Us', "FAQ", "Vision"];
const pages = [
  "Home",
  // "About Us",
  "Own a Franchise",
  "Menu",
  "Be a property partner",
  "Nearby Chatpata",
  "Feedback",
];
const drawerWidth = 240;

function Header(props) {
  const { window } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const imageUrls = [getImageLink("/logo.jpg")];
  preloadImages(imageUrls);

  const isActivePage = (page = "home") => {
    if (
      page.toLowerCase() === "home" &&
      location.pathname.split("/")[1] === ""
    ) {
      return true;
    }
    return (
      page.toLowerCase().replaceAll(" ", "-") ===
      location.pathname.split("/")[1]
    );
  };

  const navigateToPage = (page = "home") => {
    return navigate(
      page.toLowerCase() === "home"
        ? "/"
        : page.toLowerCase().replaceAll(" ", "-")
    );
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {APP_NAME}
      </Typography>
      <Divider />
      <List>
        {pages.map((item) => (
          <ListItem
            sx={isActivePage(item) ? { background: "rgb(234, 90, 38)" } : {}}
            key={item}
            disablePadding
          >
            <ListItemButton
              onClick={() => navigateToPage(item)}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      <HideOnScroll>
        <AppBar>
          <Container maxWidth="xl">
            <Toolbar disableGutters sx={{ minHeight: "60px" }}>
              <Box
                sx={{
                  display: {
                    width: "100%",
                    xs: "none",
                    md: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ mr: 1 }}>
                    <img
                      src={getImageLink("/logo.jpg")}
                      onClick={() => navigate("")}
                      style={{ cursor: "pointer" }}
                      alt="logo"
                      width="250px"
                    />
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  {pages.map((page) => (
                    <Button
                      key={page}
                      onClick={() => navigateToPage(page)}
                      sx={{
                        my: 2,
                        color: isActivePage(page) ? "black" : "white",
                        display: "block",
                      }}
                    >
                      {page}
                    </Button>
                  ))}
                </Box>
                <Box>
                  {" "}
                  <Typography
                    sx={{
                      border: "3px solid white",
                      px: 2,
                      borderRadius: "5px",
                    }}
                    variant="h6"
                  >
                    Since 2014
                  </Typography>{" "}
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <IconButton
                    size="large"
                    aria-controls="open-drawer"
                    aria-haspopup="true"
                    onClick={handleDrawerToggle}
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    sx={{ mr: 2 }}
                  >
                    <MdMenu />
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    mr: 1,
                    width: "calc(100vw - 110px)",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={getImageLink("/logo.jpg")}
                    onClick={() => navigate("")}
                    style={{ cursor: "pointer" }}
                    alt="logo"
                    width="180px"
                  />
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar id="back-to-top-anchor" />
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
export default Header;
