import { Box, Grid, Paper } from "@mui/material";
import React from "react";
import TotalCard from "../components/TotalCard";
import { useOutletContext } from "react-router-dom";

const AdminDashboard = () => {
  const { allData } = useOutletContext();

  return (
    <Box
      my={7}
      mx="5vw"
      className="about main-background-image"
      sx={{ display: { xs: "block", md: "flex" }}}
    >
      <Grid maxWidth="1080px" container spacing={3}>
        <Grid item xs={12} md={4}>
          <TotalCard
            name="Property Partner"
            link="property-partner"
            total={allData?.propertyPartner?.length || 0}
            color="white"
            background="red"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TotalCard
            name="Franchise"
            link="franchise"
            total={allData?.franchise?.length || 0}
            color="white"
            background="orange"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TotalCard
            name="Feedback"
            link="feedback"
            total={allData?.feedback?.length || 0}
            color="white"
            background="green"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
