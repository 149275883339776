import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import StoreLocator from "./pages/StoreLocator";
import ErrorPage from "./pages/ErrorPage";
import Root from "./pages/Root";
import Stores from "./pages/Stores";
import Menu from "./pages/Menu";
import AboutUs from "./pages/AboutUs";
import FAQs from "./pages/FAQs";
import Vision from "./pages/Vision";
import PropertyPartnership from "./pages/PropertyPartnership";
import Franchise from "./pages/Franchise";
import BrandsVision from "./pages/BrandsVision";
import ProductLine from "./pages/ProductLine";
import HowToContact from "./pages/HowToContact";
import FranchiseModels from "./pages/FranchiseModels";
import WhyToFranchiseWithUs from "./pages/WhyToFranchiseWithUs";
import Feedback from "./pages/Feedback";
import RoadMap from "./pages/RoadMap";
import LogIn from "./pages/LogIn";
import ProtectedRoute from "./pages/ProtectedRoute";
import AdminDashboard from "./pages/AdminDashboard";
import FranchiseList from "./pages/FranchiseList";
import PropertyPartnerList from "./pages/PropertyPartnerList";
import FeedbackList from "./pages/FeedbackList";
import Logout from "./pages/Logout";
import { SpeedInsights } from '@vercel/speed-insights/react';

const router = createBrowserRouter([
  {
    path: "admin/",
    element: <ProtectedRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "logout/",
        element: <Logout />,
      },
      {
        path: "feedback/",
        element: <FeedbackList />
      },
      {
        path: "franchise/",
        element: <FranchiseList />
      },
      {
        path: "property-partner/",
        element: <PropertyPartnerList />
      },
      {
        path: "",
        element: <AdminDashboard />
      }
    ]
  },
  {
    path: "login/",
    element: <LogIn />,
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "about-us/",
        element: <AboutUs />,
      },
      {
        path: "be-a-property-partner/",
        element: <PropertyPartnership />,
      },
      {
        path: "feedback/",
        element: <Feedback />,
      },
      {
        path: "menu/",
        element: <Home />,
      },
      {
        path: "nearby-chatpata/",
        element: <StoreLocator />,
      },
      {
        path: "own-a-franchise/",
        element: <Franchise />,
        children: [
          {
            path: "overview/",
            element: <BrandsVision />,
          },
          {
            path: "faqs/",
            element: <FAQs />,
          },
          {
            path: "franchise-roadmap/",
            element: <RoadMap />,
          },
          {
            path: "franchise-store-model/",
            element: <FranchiseModels />,
          },
          {
            path: "apply-here/",
            element: <HowToContact />,
          },
          // {
          //   path: "product-basket/",
          //   element: <ProductLine />,
          // },
          {
            path: "chatpata-advantage/",
            element: <WhyToFranchiseWithUs />,
          },
        ],
      },
      {
        path: "vision/",
        element: <Vision />,
      },
      {
        path: "",
        element: <Menu />,
      },
    ],
  },
]);

function App() {
  return (
    <div className="app">
      <RouterProvider router={router} />
      <SpeedInsights />
    </div>
  );
}

export default App;
