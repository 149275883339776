import { Typography } from '@mui/material'
import React from 'react'
import theme from '../common/theme'

const Title = ({ variant, children, sx={}, mt=0 }) => {
    return (
        <Typography
            variant={variant || "h4"}
            mb={5}
            mt={mt}
            color={variant ? theme.palette.primary.title : theme.palette.primary.main}
            sx={{textAlign: 'center', ...sx}}
        >
            {children}
        </Typography>
    )
}

export default Title