import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./ContactUs.css";
import { useWindowSize } from "@uidotdev/usehooks";
import { getImageLink, preloadImages } from "../common/functions";

const StoreLocator = () => {

  const imageUrls = [
    getImageLink("/assets/models/Food.jpg")
  ];
  preloadImages(imageUrls);

  const size = useWindowSize();
  const [states, setStates] = useState([
    {
      name: "Gujarat",
      state_code: "GJ",
    },
  ]);
  // const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState([
    "Ahmedabad",
    "Navsari",
    "Rajkot",
    "Surat",
    "Vadodara",
  ]);

  const [area, setArea] = useState([
    {
      city: "Surat",
      areas: ["Dindoli", "Udhna", "Althan", "Bhestan", "Pandesara", "KSB Olympia", "Palanpur Patiya", "Galaxy Circle - Pal"],
    },
  ]);

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const getAreaList = () => {
    const index = area.findIndex((v) => v.city === selectedCity);
    return index === -1 ? [] : area[index].areas;
  };
  const branchList = [
    {
      id: "Dindoli",
      name: "Chatpata Dindoli",
      address:
        "Shop No. 1 & 2, Dream Shoppers, Opp. Dindoli Police Statopn, Dindoli Kharwasa Road, Dindoli, Surat - 394210.",
      contact: "+91 88669 99283",
      map: "https://maps.google.com/maps?q=Chatpata+Dindoli%2C+Dindoli+Kharwasa+Road%2C+Dindoli%2C+Surat+-+394210.&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      id: "Udhna",
      name: "Chatpata Udhna",
      address:
        "Shop No. 3, Plot No. 62 - A, Mira Krupa Co. Op. Socity, near Jhansi Ki Rani Garden, Udhana Gam, Meera Nagar, Udhana Village, Udhana, Surat, Gujarat 394210.",
      contact: "+91 99242 24635",
      map: "https://maps.google.com/maps?q=Chatpata+Shop+No.+3%2C+Plot+No.+62+-+A%2C+Mira+Krupa+Co.+Op.+Socity%2C+near+Jhansi+Ki+Rani+Garden%2C+Udhana+Gam%2C+Meera+Nagar%2C+Udhana+Village%2C+Udhana%2C+Surat%2C+Gujarat+394210.&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      id: "Althan",
      name: "Chatpata Althan",
      address:
        "Shop No. G/13, Raj Dream, B/S Devbhumi Residency Nr Bhagwan Mahavir College, Vesu Main Road, Bhimrad, Surat, Gujarat 395017.",
      contact: "+91 93289 36297",
      map: "https://maps.google.com/maps?q=Chatpata+Shop+No.+G%2F13%2C+Raj+Dream%2C+B%2FS+Devbhumi+Residency+Nr+Bhagwan+Mahavir+College%2C+Vesu+Main+Road%2C+Bhimrad%2C+Surat%2C+Gujarat+395017.&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      id: "Bhestan",
      name: "Chatpata Bhestan",
      address:
        "Shop. No. G - 20 & 21, Sai Plaza Complex, Priyanka Chokdi, Jiav - Sonari Road, Bhestan, Surat - 394210.",
      contact: "+91 93136 45559",
      map: "https://maps.google.com/maps?q=Chatpata+Bhestan%2C+Sonari%2C+Surat%2C+Gujarat+395023&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      id: "Pandesara",
      name: "Chatpata Pandesara",
      address:
        "Shop No. 1 & 2, B/ 197, LIG 336, Jalaram Nagar, G.H.Board, Near Pandesara Vegetable Market, Opp. Police Choki, Pandesara, Surat - 394221.",
      contact: "+91 91067 46234",
      map: "https://maps.google.com/maps?q=Chatpata+Jalaram+Nagar%2C+G.H.Board%2C+Near+Pandesara+Vegetable+Market%2C+Opp.+Police+Choki%2C+Pandesara%2C+Surat+-+394221.&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      id: "KSB Olympia",
      name: "Chatpata KSB Olympia",
      address:
        "G - 7, KSB OLYMPIA, ALTHAN ROAD, Kailash Chokdi, Pandesara, Udhana, Surat, Gujarat 394221",
      contact: "+91 73596 93565",
      map: "https://maps.google.com/maps?q=Chatpata+KSB+OLYMPIA%2C+ALTHAN+ROAD%2C+Kailash+Chokdi%2C+Gopal+Nagar+Society%2C+Pandesara%2C+Udhana%2C+Surat%2C+Gujarat+-+394221.&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      id: "Palanpur Patiya",
      name: "Chatpata Palanpur Patiya",
      address:
        "G - 16 - Green Palladia, Palanpur Canal Road, Opposite Raj World Shopping Center, Palanpur Jakatnaka, Surat, Gujarat 395005.",
      contact: "+91 76008 70970",
      map: "https://maps.google.com/maps?q=Chatpata%20Palanpur%20Patiya,%20Green%20Palladia,%20Palanpur%20Jakatnaka,%20Adajan,%20Surat,%20Gujarat%20395005.&t=&z=13&ie=UTF8&iwloc=&output=embed",
    },
    {
      id: "Galaxy Circle - Pal",
      name: "Chatpata GALAXY CIRCLE",
      address:
        "Shop No. 1 - La Victoria, Near Galaxy Circle, Opp Maruti Suzuki Showroom, Pal Gam, Surat - 395009",
      contact: "+91 83204 81180",
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.062802616289!2d72.77684187516923!3d21.18966398050025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04d78a6667855%3A0xae2e10bd58b84217!2sLa%20Victoria!5e0!3m2!1sen!2sin!4v1713099125380!5m2!1sen!2sin",
    },
  ];

  const handleChangeArea = (e) => {
    const value = e.target.value;
    const data = branchList.find((v) => v.id === value);
    setSelectedArea(data);
  };

  return (
    <Box my="3rem" className="contact-us main-background-image">
      <Box fontWeight={500} className="content" m="2vw" textAlign="justify">
        <p style={{ textAlign: "justify" }}>
          <strong>
            Thank you for choosing "Chatpata: Khao - Piyo - Aish Karo..."
          </strong>
        </p>
        <p style={{ textAlign: "justify" }}>
          We can't wait to serve you and create lasting flavour memories
          together. Come join us at one of our locations and embark on a
          culinary adventure that will leave you saying,
        </p>
        <p className="title-color tex-center" style={{ fontWeight: "bold" }}>
          "That was Chatpata - Khaya - Piya - Aish Kiya ..."{" "}
        </p>
      </Box>
      <Box width="100%">
        <Box m={2} p={3} className="content box text-center">
          <img
            style={{
              border: "1px solid black",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "1px 1px 4px 1px gray",
              maxWidth: "400px",
              width: "70vw",
            }}
            src={getImageLink("/assets/models/Food.jpg")}
            alt="food express"
          />

          <Typography
            className="title-color"
            fontWeight={500}
            my={4}
            style={{ marginBottom: "2rem" }}
            variant="h5"
            gutterBottom
          >
            Locate Your Nearest CHATPATA
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "space-evenly",
            }}
          >
            <FormControl sx={{ minWidth: "150px", width: "30%" }}>
              <InputLabel id="demo-simple-select-label-1">State</InputLabel>
              <Select
                labelId="demo-simple-select-label-1"
                id="demo-simple-select-1"
                value={states[0].name || ""}
                label="State"
                // onChange={handleStateChange}
              >
                {states.map((value, index) => (
                  <MenuItem key={index} value={value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "150px", width: "30%" }}>
              <InputLabel id="demo-simple-select-label-2">City</InputLabel>
              <Select
                labelId="demo-simple-select-label-2"
                id="demo-simple-select-2"
                value={selectedCity || ""}
                label="City"
                onChange={(e) => setSelectedCity(e.target.value)}
              >
                {cities.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "150px", width: "30%" }}>
              <InputLabel id="demo-simple-select-label-3">Store</InputLabel>
              <Select
                labelId="demo-simple-select-label-3"
                id="demo-simple-select"
                value={selectedArea?.id || ""}
                label="Store"
                onChange={handleChangeArea}
              >
                {getAreaList().map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {selectedArea ? (
          <Box m={2} p={3} className="content box text-center">
            <Typography
              fontWeight={500}
              className="title-color"
              variant="h5"
              gutterBottom
            >
              {selectedArea.name}
            </Typography>
            <Box display="flex" justifyContent="center">
              <Box maxWidth={600}>
                <p>{selectedArea.address}</p>
                <p style={{ textAlign: "center" }}>
                  <strong>Contact: {selectedArea.contact}</strong>
                </p>
              </Box>
            </Box>
            <Box my={5} display="flex" justifyContent="center">
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    width={size.width - 100}
                    style={{ maxWidth: "820px" }}
                    height="560"
                    id="gmap_canvas"
                    src={selectedArea.map}
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                  <a href="https://timenowin.net/">online clock</a>
                  <br />
                  <a href="https://www.clock-alarm.com/"></a>
                  <br />
                  <a href="https://www.ongooglemaps.com">
                    embedding maps in website
                  </a>
                </div>
              </div>
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: "30vh" }}></Box>
        )}
      </Box>
    </Box>
  );
};

export default StoreLocator;
