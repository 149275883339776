import React from "react";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { MdExpandMore } from "react-icons/md";
import { useWindowSize } from "@uidotdev/usehooks";
import { getImageLink, preloadImages } from "../common/functions";

const faqs = [
  {
    q: `1. What are the financial requirements to become a Chatpata’s franchisee?`,
    a: `The financial requirements for franchisees include an initial franchise fee (non-refundable), which is INR 5 Lacs + GST, as well as sufficient capital for startup costs, it may vary depending
        on factors such as location and business size. Generally, the store setup costs around 15 Lacs + GST (excluding franchisee fee) for 200 to 400 sq. ft. carpet area.`,
  },
  {
    q: `2. What does franchisee fee include?`,
    a: `Franchisee fee includes access to our brand, training, ongoing support, marketing materials, proprietary systems, and expertise to help you start
        and operate your franchise successfully.`,
  },
  {
    q: `3. Does franchisee need to pay any royalty fees? why?`,
    a: `Yes, franchisees are required to pay a royalty fee of 5% + GST on Net Sales and 2% + GST for marketing program. This fee is essential to support ongoing services, including marketing,
        training, operational support, and the continued development of the brand. It allows us to maintain and enhance the overall strength and success of our franchise system.`,
  },
  {
    q: `4. Is prior restaurant or business experience required to become a franchisee?`,
    a: `While prior restaurant or business experience can be beneficial, it's not always a strict requirement, we evaluate candidates based on various factors, like qualifications, commitment,
        and ability to follow our franchise system. We provide comprehensive training and support to ensure franchisees are well-prepared for success, regardless of their prior experience.`,
  },
  {
    q: `5. How do I select a location for Chatpata’s Franchise Store, and do you assist with site selection?`,
    a: `Selecting the right location is critical to success as a franchisee. Chatpata’s team will work with you to assess potential locations based on factors such as demographics, foot traffic,
        visibility, and local market analysis. We'll collaborate to find the most suitable site that aligns with our brand's requirements and business goals.`,
  },
  {
    q: `6. What is the typical timeline from inquiry to opening a Chatpata’s Franchisee Store?`,
    a: `It can vary depending on several factors, including site selection, training, and local regulations. However, on average, the process takes approximately 45 days to 90 days. We work
        closely with franchisees to streamline the process and ensure a successful opening.`,
  },
  {
    q: `7. What is the term of the franchise agreement, and can it be renewed?`,
    a: `The term of our franchise agreement typically lasts for 5 years, with an option for renewal upon mutual agreement. Renewal terms and conditions are outlined in the franchise
        agreement. We encourage franchisees in good standing to explore renewal options to continue their partnership with us.`,
  },
  {
    q: `8. What are the expectations for franchisee involvement in day-to-day operations?`,
    a: `We expect franchisees to actively manage and oversee day-to-day operations, this includes responsibilities such as staffing, customer service, inventory management, and ensuring
        adherence to our brand standards and operating procedures.`,
  },
  {
    q: `9. Are there opportunities for multi-unit franchise ownership?`,
    a: `Yes, we offer opportunities for multi-unit franchise ownership. We welcome individuals who are interested in expanding their franchise portfolio and are willing to discuss the possibilities
        and requirements for owning and operating multiple units within our franchise system.`,
  },
  {
    q: `10. What ongoing support and resources are available after the franchise is open and operational?`,
    a: `After your franchise is open and operational, we provide comprehensive ongoing support and resources to help you succeed. This includes continued training for you and your staff,
        access to updated marketing materials and strategies, regular communication and guidance from our corporate team and assistance with operational challenges. Our commitment to
        your success extends well beyond the initial opening, and we are dedicated to helping you thrive in your franchise business.`,
  },
  {
    q: `11. Is success guaranteed when purchasing a franchise?`,
    a: `Success in franchising is not guaranteed. While purchasing a franchise provides you with a proven business model, established brand recognition, and ongoing support, your ultimate
        success depends on various factors, including your dedication, management skills, market conditions, and the effort you invest in your business. We provide the tools, training, and
        guidance to help you maximize your chances of success, but it's essential to understand that like any business venture, there are risks involved. We recommend conducting thorough
        due diligence, seeking professional advice, and carefully evaluating your own commitment and resources before making a decision to purchase a franchise.`,
  },
  {
    q: `12. How will you help me, if my franchisee store's business does not pick up?`,
    a: `If your franchisee store's sales are not picking up, we have a support system in place to address such situations. Our team will work closely with you to assess the challenges and provide
        guidance and strategies to help improve sales and the overall performance of your franchise unit. We are committed to supporting franchisees in challenging situations, which may
        include assisting with store relocation or exploring options for transferring or replacing a franchisee when necessary.`,
  },
  {
    q: `13. Where do we get the raw materials from?`,
    a: `The franchisee will get the essential raw materials from Chatpata's central warehouse to maintain top-notch quality aligned with the brand-name. It can only purchase fresh vegetables independently; all other raw material procurement is centralized to ensure consistency and brand standards.`,
  },
  {
    q: `14. Am I allowed to add my own products or items to the menu?`,
    a: `No, adding your own products or items to the menu is not permitted. The restriction is in place to maintain consistency and adhere to the standardized offerings of the brand. This ensures that customers experience the expected quality and variety across all franchise locations.`,
  },
];

const FAQ = () => {
  const size = useWindowSize();
  const imageUrls = [
    getImageLink(
      size.width >= 768 ? "/assets/faq.jpg" : "/assets/mobile-faq.jpg"
    )
  ];
  preloadImages(imageUrls);


  return (
    <Box>
      <Box>
        <img
          style={{ width: "100%", maxHeight: "70vh" }}
          src={getImageLink(
            size.width >= 768 ? "/assets/faq.jpg" : "/assets/mobile-faq.jpg"
          )}
          alt="FAQ"
        />
      </Box>
      {/* <Container sx={{ my: 7 }} > */}
      {/* <Title>Clear Your Doubts Here...</Title> */}
      {faqs.map((item, key) => (
        <Accordion elevation={5} sx={{ m: 1, borderRadius: "5px", border: 0 }}>
          <AccordionSummary
            expandIcon={<MdExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="roboto" sx={{ fontWeight: "bold" }}>
              {item.q}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="roboto" sx={{ textAlign: "justify" }}>
              {item.a}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
