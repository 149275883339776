import React, { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { Box, Grid } from "@mui/material";

const DateRangeDataFilter = ({ data, setData }) => {
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  useEffect(() => {
    if (startDate || endDate) {
      const newData = data.filter((value) => {
        // Assuming created_at is a string representing a date in a compatible format
        const createdAt = dayjs(value.created_at);

        // Filter based on startDate and endDate
        if (startDate && endDate) {
          return createdAt.isBetween(
            dayjs(startDate),
            dayjs(endDate),
            null,
            "[]"
          );
        } else if (startDate) {
          return createdAt.isSame(dayjs(startDate)) || createdAt.isAfter(dayjs(startDate));
        } else {
          return createdAt.isBefore(dayjs(endDate));
        }
      });

      // Do something with the filtered data, e.g., set it to a state variable
      setData(newData);
    }
  }, [startDate, endDate]);

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "DatePicker"]}>
          <Grid container m={3}>
            <Grid item xs={12} md={6} p={2}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(startDate) => setStartDate(startDate)}
                format="YYYY-MM-DD"
              />
            </Grid>
            <Grid item xs={12} md={6} p={2}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(endDate) => setEndDate(endDate)}
                format="YYYY-MM-DD"
              />
            </Grid>
          </Grid>
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  );
};

export default DateRangeDataFilter;
