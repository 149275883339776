import React from "react";
import theme from "../common/theme";
import { Box } from "@mui/material";
import { getImageLink, preloadImages } from "../common/functions";
import { useNavigate } from "react-router-dom";

const Footer = (props) => {
  const navigate = useNavigate();
  const imageUrls = [
    getImageLink("/logo.jpg"),
    getImageLink("/assets/instagram.svg"),
    getImageLink("/assets/facebook.svg"),
    getImageLink("/assets/youtube.svg"),
    getImageLink("/assets/x-twitter.png"),
    getImageLink("/assets/call.png"),
  ];
  preloadImages(imageUrls);
  return (
    <Box sx={{ borderTop: "5px solid black" }} color="white" textAlign="center">
      <Box>
        <Box
          p={3}
          display="flex"
          gap={3}
          justifyContent="space-around"
          alignItems="center"
          flexWrap="wrap"
          bgcolor={theme.palette.primary.main}
        >
          <Box width="250px">
            <Box sx={{ border: "3px solid", borderRadius: "5px" }}>
              <img
                src={getImageLink("/logo.jpg")}
                onClick={() => navigate("")}
                style={{ cursor: "pointer" }}
                alt="logo"
                width="100%"
              />
            </Box>
          </Box>
          <Box maxWidth="800px">
            <h2 style={{ marginBottom: "5px" }}>Head Office</h2>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <Box className="roboto">S - 14, 2nd Floor, KSB Olympia,</Box>
              <Box className="roboto">Above Zudio Store, Kailash Chokdi,</Box>
              <Box className="roboto">Pandesara - Althan Road,</Box>
              <Box className="roboto">Surat, Gujarat - 394221.</Box>
              <Box sx={{ display: "flex", alignItems: "center", width:"100%", justifyContent: "center" }}>
                <Box>
                  <img width="35px" src={getImageLink("/assets/call.png")} alt="call" />
                </Box>
                <Box>8320514020</Box>
              </Box>
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Box className="roboto">
                S - 14, 2nd Floor, KSB Olympia, Above Zudio Store, Kailash
                Chokdi,
              </Box>
              <Box className="roboto">
                Pandesara - Althan Road, Surat, Gujarat - 394221.
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width:"100%", justifyContent: "center" }}
                className="roboto"
              >
                <Box>
                  <img width="35px" src={getImageLink("/assets/call.png")} alt="call" />
                </Box>
                <Box>8320514020</Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          gap={3}
          p={3}
          justifyContent="space-around"
          alignItems="center"
          borderTop="5px solid"
          bgcolor="black"
          flexWrap="wrap"
        >
          <Box width="250px">
            <Box>
              <a
                href="https://www.instagram.com/apnachatpata?igsh=MTNiYzNiMzkwZA=="
                target="_blank"
              >
                <img
                  width="40px"
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    margin: "5px",
                    border: "1px solid rgb(234, 90, 38)",
                  }}
                  src={getImageLink("/assets/instagram.svg")}
                  alt="instagram"
                />
              </a>
              <a
                href="https://www.facebook.com/apnachatpata?mibextid=ZbWKwL"
                target="_blank"
              >
                <img
                  width="40px"
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    margin: "5px",
                    border: "1px solid rgb(234, 90, 38)",
                  }}
                  src={getImageLink("/assets/facebook.svg")}
                  alt="facebook"
                />
              </a>
              <img
                width="40px"
                style={{
                  padding: "5px",
                  borderRadius: "5px",
                  margin: "5px",
                  border: "1px solid rgb(234, 90, 38)",
                }}
                src={getImageLink("/assets/youtube.svg")}
                alt="whatsapp"
              />
              <img
                width="40px"
                style={{
                  padding: "5px",
                  borderRadius: "5px",
                  margin: "5px",
                  border: "1px solid rgb(234, 90, 38)",
                }}
                src={getImageLink("/assets/x-twitter.png")}
                alt="whatsapp"
              />
            </Box>
          </Box>
          <Box maxWidth="800px">
            © 2024 All Rights Reserved By CHATPATA - Khao - Piyo - Aish Karo...
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
