import React from "react";
import Header from "../components/Header";
import { Outlet } from "react-router-dom";
import ScrollTop from "../components/ScrollToTop";
import Footer from "../components/Footer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { getImageLink, preloadImages } from "../common/functions";

const Root = () => {
  const imageUrls = [
    getImageLink("/assets/store-form.jpeg"),
    getImageLink("/assets/whatsapp.svg"),
  ];
  preloadImages(imageUrls);

  return (
    <Box>
      <Header />
      <Box>
        <Outlet />
      </Box>
      <Footer />
      <Box
        role="presentation"
        sx={{
          position: "fixed",
          bottom: 60,
          right: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 2000,
        }}
      >
        <Link target="_blank" to="https://whatsapp.com/channel/0029Va9Pj1kLdQeiqULcDh31">
          <img
            width="60px"
            style={{
              padding: "5px",
              margin: "5px",
            }}
            src={getImageLink("/assets/whatsapp.svg")}
            alt="whatsapp"
          />
        </Link>
        <Link to="/own-a-franchise/franchise-store-model">
          <img
            width="60px"
            style={{
              margin: "5px",
              border: "5px solid rgb(234, 90, 38)",
              borderRadius: "5px",
            }}
            src={getImageLink("/assets/store-form.jpeg")}
            alt="franchise"
          />
        </Link>
      </Box>
      <ScrollTop />
    </Box>
  );
};

export default Root;
