import axios from "axios";
import { TOKEN_KEY } from "./common/constant";
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API,
    // baseURL: "http://localhost:3001"
})

export const initializeToken = (token) => {
    if (!token) return;
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    localStorage.setItem(TOKEN_KEY, token);
}

export const users = {
    login: data => axiosInstance.post(`/auth/login`, data),
}

// Franchise API's
export const franchise = {
    get: () => axiosInstance.get("/franchise"),
    create: data => axiosInstance.post('/franchise', data),
    update: (id, data) => axiosInstance.put(`/franchise/${encodeURIComponent(id)}`, data),
    delete: id => axiosInstance.delete(`/franchise/${encodeURIComponent(id)}`),
} 

// Property Partner API's
export const propertyPartner = {
    get: () => axiosInstance.get("/propertyPartner"),
    create: data => axiosInstance.post('/propertyPartner', data),
    update: (id, data) => axiosInstance.put(`/propertyPartner/${encodeURIComponent(id)}`, data),
    delete: id => axiosInstance.delete(`/propertyPartner/${encodeURIComponent(id)}`),
}

// Feedback API's
export const feedback = {
    get: () => axiosInstance.get("/feedback"),
    create: data => axiosInstance.post('/feedback', data),
    update: (id, data) => axiosInstance.put(`/feedback/${encodeURIComponent(id)}`, data),
    delete: id => axiosInstance.delete(`/feedback/${encodeURIComponent(id)}`),
}

export const getStates = () => {
    // return axios.post("https://countriesnow.space/api/v0.1/countries/states", {
    //     "country": "India"
    // })
}

export const getCities = (state) => {
    // return axios.post("https://countriesnow.space/api/v0.1/countries/state/cities", {
    //     "country": "India",
    //     state
    // })
}
