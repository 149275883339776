import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';

const theme = createTheme({
  typography: {
    fontFamily: `'Mouse Memoirs', "Roboto", sans-serif`,
    fontSize: 24,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    letterSpacing: "2px",
  },
  palette: {
    primary: {
      main: "rgb(234, 90, 38)",
      second: "rgb(200, 90, 38)",
      title: red[500],
    },
  },
});

export default responsiveFontSizes(theme);