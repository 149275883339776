import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from 'swiper/modules';
import './HomeSwipper.css';
import { Box } from '@mui/material';
import { getImageLink, preloadImages } from '../common/functions';

const HomeSwipper = () => {
  const imageUrls = [
    getImageLink("/assets/home/desktop/2-11-23.jpeg"),
    getImageLink("/assets/home/desktop/6-11-23.jpeg"),
    getImageLink("/assets/home/desktop/7-11-23.jpeg"),
    getImageLink("/assets/home/desktop/8-11-23.jpeg"),
    getImageLink("/assets/home/desktop/9-11-23.jpeg"),
    getImageLink("/assets/home/desktop/12-11-23.jpeg",),
    getImageLink("/assets/home/desktop/16-11-23.jpeg"),
    getImageLink("/assets/home/desktop/17-11-23.jpeg"),
    getImageLink("/assets/home/desktop/20-11-23.jpeg"),
    getImageLink("/assets/home/desktop/22-11-23.jpeg"),
    getImageLink("/assets/home/desktop/23-11-23.jpeg"),
    getImageLink("/assets/home/desktop/coco1.jpeg"),
  ];
  preloadImages(imageUrls);

  return (
    <Box className="home__swipper" sx={{ height: { xs: "62vh", lg: "86vh" } }} >
      <Swiper
        loop={true}
        autoplay={{
          delay: 1500,
        }}
        pagination={{
          dynamicBullets: true,
          clickable: true
        }}
        // navigation={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/2-11-23.jpeg")} alt="restaurent" /></SwiperSlide>
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/6-11-23.jpeg")} alt="sandwich" /></SwiperSlide>
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/7-11-23.jpeg")} alt="sandwich" /></SwiperSlide>
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/8-11-23.jpeg")} alt="sandwich" /></SwiperSlide>
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/9-11-23.jpeg")} alt="sandwich" /></SwiperSlide>
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/12-11-23.jpeg")} alt="sandwich" /></SwiperSlide>
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/16-11-23.jpeg")} alt="sandwich" /></SwiperSlide>
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/17-11-23.jpeg")} alt="sandwich" /></SwiperSlide>
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/20-11-23.jpeg")} alt="sandwich" /></SwiperSlide>
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/22-11-23.jpeg")} alt="sandwich" /></SwiperSlide>
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/23-11-23.jpeg")} alt="sandwich" /></SwiperSlide>
        <SwiperSlide><img height="80vh" width="auto" src={getImageLink("/assets/home/desktop/coco1.jpeg")} alt="sandwich" /></SwiperSlide>
      </Swiper>
    </Box>
  )
}

export default HomeSwipper