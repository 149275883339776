import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const TotalCard = ({
  name,
  link,
  total,
  background = "white",
  color = "black",
}) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" justifyContent="center" >
      <Card
        onClick={() => navigate(link || "#")}
        sx={{
          cursor: "pointer",
          background: background,
          color: color,
          fontWeight: "bold",
          minHeight: "150px",
          maxWidth: "320px",
          width: "100%"
        }}
      >
        <CardContent>
          <Box mb={2}>
            <Typography variant="h4" component="div">
              {total}
            </Typography>
          </Box>
          <Typography variant="h5" component="div">
            {name}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TotalCard;
