import {
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import AboutUs from "./AboutUs";
import { getImageLink, preloadImages } from "../common/functions";

const Menu = () => {
  const size = useWindowSize();
  const imageUrls = [
    getImageLink(size.width < 768 ? "/assets/mobile-backimage.jpeg" : "/assets/backimage.jpg"),
    getImageLink("/assets/aboutus/photo%202.jpg"),
  ];
  preloadImages(imageUrls);

  return (
    <>
      <Box sx={{ background: "black" }}>
        <Box mt={2.5} className="image-container">
          <img
            width="100%"
            src={getImageLink(size.width < 768 ? "/assets/mobile-backimage.jpeg" : "/assets/backimage.jpg")}
            alt="backimage"
            className="menu-back"
          />
          <Box className="image-text">
            <p className="f45">Welcome to</p>
            <p className="f6">Chatpata</p>
            <p className="f45">KHAO - PIYO - AISH KARO...</p>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "90px",
          background: "rgb(234, 90, 38)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <Typography
          variant="h4"
          sx={{ textShadow: "0 0 3px black, 0 0 5px black" }}
        >
          About Us
        </Typography>
      </Box>
      <Box>
        <AboutUs />
      </Box>
    </>
  );
};

export default Menu;
