import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { initializeToken } from '../api';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('token');
    initializeToken(" ")
    navigate("/login")
  }, [])
  
  return (
    <div>Logout</div>
  )
}

export default Logout