import { Box, Container, Typography } from "@mui/material";
import React from "react";
import CustomCard from "../components/CustomCard";
import Title from "../components/Title";
import { getImageLink } from "../common/functions";

const aboutUsCards = [
  {
    title: "Quality",
    description: `We never compromise on the quality of our ingredients. From the freshest vegetables to the
    choicest spices and cheeses, we source the best to serve you the best.`,
  },
  {
    title: "Variety",
    description: `Our extensive menu is designed to cater to a wide range of tastes. You'll always find something
    that tickles your taste buds.`,
  },
  {
    title: "Speed",
    description: `We understand that sometimes you're in a hurry, and that's where we shine. Our quick service
    ensures that you get your food fast without sacrificing taste or quality.`,
  },
  {
    title: "Customer Satisfaction",
    description: `Your satisfaction is our top priority. We value your feedback and continuously
    strive to improve our offerings to exceed your expectations`,
  },
];

const AboutUs = () => {
  return (
    <Box my={5} mx="5vw" className="about center-main main-background-image" sx={{ display: { xs: "block", md: "flex" } }}  >
      <Box sx={{ width: { xs:"100%", md: "30%" } }}  >
        <Box className="content" sx={{display: "flex", flexDirection: "column", alignItems: "center" }} >
          <Typography
            fontWeight={500}
            className="title-color"
            variant="h5"
            gutterBottom
            sx={{border: "3px solid black", width:"200px", borderRadius: "5px" }}
          >
            Brains Behind Chatpata
          </Typography>
          <img width="300px" src={getImageLink("/assets/aboutus/photo%202.jpg")} alt="" />
        </Box>
      </Box>
      <Box sx={{ width: { xs:"100%", md: "70%" } }} >
        <Box className="content" m="2vw">
          <Typography
            fontWeight={500}
            className="title-color"
            variant="h4"
            gutterBottom
          >
            Our Delicious Saga
          </Typography>
          <p>
            Meet the dynamic duo behind Chatpata — <strong>Prashant Pal</strong>{" "}
            and <strong>Sanjay Pogul</strong>, friends turned entrepreneurs who
            planted the seeds of our flavourful journey back in October 2014. As
            friends and colleagues, they shared a common goal: to cater to the
            appetites of Indian youngsters with a menu that's diverse,
            delicious, and dynamic.
          </p>
          <p>
            Driven by the belief that good food should be accessible to all, we
            embarked on a mission to provide a variety of delectable dishes.
            Quality and hygiene are at the core of our offerings, ensuring each
            bite is not just flavourful but also safe and wholesome.
          </p>
          <p>
            At Chatpata, we take pride in being pocket-friendly, making sure
            that everyone can indulge in a delicious experience without breaking
            the bank.
          </p>
          <p></p>
          <p>Brace yourself for a taste explosion!</p>
          <p>
            We're itching to serve you and cook up flavourful memories that'll
            stick with you longer, and that catchy jingle you can't get out of
            your head and that'll make you shouting,
          </p>
          {/* <p>
            In <strong>October’ 2014</strong>, with the vision of becoming one
            of the coolest Quick Bite Heaven (QSR) and a dream to give fast food
            a fun makeover in our own way, the <strong>“Chatpata”</strong> was
            born.
          </p>
          <p>
            We're not just in the business of food; we're a bunch of culinary
            rebels on a mission to sprinkle magic flavour into your life. Our
            dedication to quality, variety, and service is as solid as a
            marshmallow in hot cocoa.
          </p> */}
        </Box>
        <Box className="content" my={3}>
          <Typography
            className="title-color"
            fontWeight={500}
            variant="h5"
            gutterBottom
          >
            “Wow, that was Chatpata - Khaya - Piya – aur Aish Kiya...”!!
          </Typography>
          <p style={{ textAlign: "center" }}>
            Get Ready. We're here to make your munching moments a{" "}
            <strong>Flavourful Rollercoaster!</strong>
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
