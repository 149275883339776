import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./ContactUs.css";
import Swal from "sweetalert2";
import { camelCaseToWords } from "../common/functions";
import { feedback } from "../api";

const Feedback = () => {
  const defaultFormData = {
    state: "Gujarat",
    type: "Feedback",
    level: "Store Level"
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [states, setStates] = useState([
    {
      name: "Gujarat",
      state_code: "GJ",
    },
  ]);
  const [cities, setCities] = useState([
    "Ahmedabad",
    "Navsari",
    "Rajkot",
    "Surat",
    "Vadodara",
  ]);

  const [area, setArea] = useState([
    {
      city: "Surat",
      areas: ["Dindoli", "Udhna", "Althan", "Bhestan", "Pandesara", "KSB Olympia", "Palanpur Patiya", "Galaxy Circle - Pal"],
    },
  ]);

  const problem = [
    "Brand Related",
    "Store Related",
    "Food Related",
    "Hygiene Related",
    "Staff Behaviour Related",
    "Service Related",
    "Other"
  ];

  const handleChangeForm = (e, p = null, typeConversion = null) => {
    const validPhone = /^\d{0,10}$/;

    const name = e.target.name;
    const value = typeConversion
      ? typeConversion(e.target.value)
      : e.target.value;
    let newData = {
      [name]: value,
    };
    if (name === "phone" && !value.match(validPhone)) {
      return;
    } else if (name === "type" && value === "Complaint") {
      newData.level = "Store Level";
    } else if (name === "state") {
      newData.city = "";
      newData.store = "";
    } else if (name === "city") {
      newData.store = "";
    }
    setFormData((oldData) => ({
      ...oldData,
      ...newData,
    }));
  };

  const validateAttributes = () => {
    const attributes = [
      "firstName",
      "lastName",
      "email",
      "phone",
      "type",
      "subject",
      "description",
      "city",
      "state",
      "store",
      "level",
    ];
    const complaint = ["city", "state", "store", "level"];
    const suggesstion = ["level"];
    let isNotCheckAll = false;
    const validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const validPhone = /^\d{10}$/;
    for (const value of attributes) {
      if (!formData[value]) {
        isNotCheckAll = value;
        break;
      } else if (value === "email" && !formData[value].match(validEmailRegex)) {
        isNotCheckAll = value;
        break;
      } else if (value === "phone" && !formData[value].match(validPhone)) {
        isNotCheckAll = value;
        break;
      }
    }
    if (formData?.type === "Complaint") {
      for (const value of complaint) {
        if (!formData[value]) {
          isNotCheckAll = value;
          break;
        }
      }
    }
    if (formData?.type === "Suggestion") {
      for (const value of suggesstion) {
        if (!formData[value]) {
          isNotCheckAll = value;
          break;
        }
      }
    }
    if (isNotCheckAll) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${camelCaseToWords(isNotCheckAll)} is Missing`,
      });
    }
    return !isNotCheckAll;
  };

  const handleSubmit = async () => {
    if (validateAttributes()) {
      feedback
        .create(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Congrats!",
            text: `Your Feedback Form Submitted`,
          }).then((res) => setFormData(defaultFormData));
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err?.response?.data?.message || `Error While Submitting Data`,
          });
        });
      console.log("final data", formData);
    }
  };

  const getAreaList = () => {
    const index = area.findIndex((v) => v.city === formData?.city);
    return index === -1 ? [] : area[index].areas;
  };

  return (
    <Box my="3rem" className="contact-us main-background-image">
      <Box sx={{ fontWeight: "bold" }} className="content" m="2vw">
        <Typography
          fontWeight={500}
          className="title-color"
          variant="h5"
          gutterBottom
        >
          We're here to make your dining experience truly special.
        </Typography>
        <Typography fontWeight={500} variant="h6" gutterBottom>
          If you have any questions or just want to say hello, feel free to get
          in touch with us.
        </Typography>
      </Box>
      <Box m={2} mt={0} p={3} pt={0} className="content text-center">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label-6">
                <Typography fontWeight={500} variant="h6" gutterBottom>
                  Select Your Type of Response *
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label-6"
                name="type"
                value={formData?.type || ""}
                onChange={handleChangeForm}
                sx={{
                  flexDirection: { xs: "column", sm: "row" },
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  value="Feedback"
                  sx={{ minWidth: "150px" }}
                  control={<Radio />}
                  label="Feedback"
                />
                <FormControlLabel
                  value="Suggestion"
                  sx={{ minWidth: "150px" }}
                  control={<Radio />}
                  label="Suggestion"
                />
                {/* <FormControlLabel
                  value="Complaint"
                  sx={{ minWidth: "150px" }}
                  control={<Radio />}
                  label="Complaint"
                /> */}
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label-6">
                <Typography fontWeight={500} variant="h6" gutterBottom>
                  Choose from following option? *
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label-6"
                name="level"
                value={formData?.level || ""}
                onChange={handleChangeForm}
                sx={{ flexDirection: "row", flexWrap: "wrap" }}
              >
                <FormControlLabel
                  value="Brand Level"
                  control={<Radio />}
                  label="Brand Level"
                />
                <FormControlLabel
                  value="Store Level"
                  control={<Radio />}
                  label="Store Level"
                />
              </RadioGroup>
            </FormControl>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="firstName"
              name="firstName"
              value={formData?.firstName || ""}
              onChange={handleChangeForm}
              label="First name"
              fullWidth
              autoComplete="given-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="lastName"
              name="lastName"
              value={formData?.lastName || ""}
              onChange={handleChangeForm}
              label="Last name"
              fullWidth
              autoComplete="family-name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="email"
              name="email"
              value={formData?.email || ""}
              onChange={handleChangeForm}
              label="Email"
              fullWidth
              autoComplete="example@gmail.com"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="phone"
              name="phone"
              value={formData?.phone || ""}
              onChange={handleChangeForm}
              label="Phone"
              fullWidth
              autoComplete="+919999999999"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel required id="demo-simple-select-label">
                State
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="state"
                value={formData?.state || ""}
                label="State"
                onChange={handleChangeForm}
              >
                {states.map((value, index) => (
                  <MenuItem key={index} value={value.name}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel required id="demo-simple-select-label-2">
                City
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-2"
                id="demo-simple-select-2"
                name="city"
                value={formData?.city || ""}
                label="City"
                onChange={handleChangeForm}
              >
                {cities.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel required id="demo-simple-select-label-3">
                Store
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-3"
                id="demo-simple-select-3"
                name="store"
                value={formData?.store || ""}
                label="Store"
                onChange={handleChangeForm}
              >
                {getAreaList().map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel required id="demo-simple-select-label-4">
                Subject
              </InputLabel>
              <Select
                labelId="demo-simple-select-label-4"
                id="demo-simple-select-4"
                name="subject"
                value={formData?.subject || ""}
                label="Subject"
                onChange={handleChangeForm}
              >
                {problem.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* {formData?.type === "Complaint" ? (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel required id="demo-simple-select-label-4">
                  Subject
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-4"
                  id="demo-simple-select-4"
                  name="subject"
                  value={formData?.subject || ""}
                  label="Subject"
                  onChange={handleChangeForm}
                >
                  {problem.map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="subject"
                name="subject"
                value={formData?.subject || ""}
                onChange={handleChangeForm}
                label="Subject"
                fullWidth
              />
            </Grid>
          )} */}
          <Grid item xs={12}>
            <TextField
              required
              id="description"
              name="description"
              value={formData?.description || ""}
              onChange={handleChangeForm}
              label="Your Message"
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2 }}
            >
              Click To Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Feedback;
