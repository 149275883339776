import { Box, Button, Typography } from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useOutletContext } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  convertToLocalTimeZone,
  handleExportCSV,
  handleExportExcel,
} from "../common/functions";
import DateRangeDataFilter from "../components/DateRangeDataFilter";

const PropertyPartnerList = () => {
  const { allData } = useOutletContext();
  const [data, setData] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  const filename = `PropertyPartner`;

  useEffect(() => {
    if (allData?.propertyPartner) {
      const parsedData = allData.propertyPartner.map((value) => ({
        ...value,
        created_at: convertToLocalTimeZone(value?.created_at).format(
          "YYYY-MM-DD"
        ),
      }));
      setParsedData(parsedData);
      setData(parsedData);
    }
  }, [allData]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at",
        header: "Created At",
        size: 150,
      },
      {
        accessorKey: "firstName", //access nested data with dot notation
        header: "First Name",
        size: 150,
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
        size: 150,
      },
      {
        accessorKey: "email", //normal accessorKey
        header: "Email",
        size: 200,
      },
      {
        accessorKey: "phone", //normal accessorKey
        header: "Phone",
        size: 200,
      },
      {
        accessorKey: "city",
        header: "City",
        size: 150,
      },
      {
        accessorKey: "state",
        header: "State",
        size: 150,
      },
      {
        accessorKey: "area",
        header: "Area",
        size: 150,
      },
      {
        accessorKey: "rent",
        header: "Rent",
        size: 150,
      },
      {
        accessorKey: "location",
        header: "Location",
        size: 150,
      },
      {
        accessorKey: "ownership",
        header: "Ownership",
        size: 150,
      },
      {
        accessorKey: "propertyType",
        header: "Property Type",
        size: 150,
      },
      {
        accessorKey: "propertyDescription",
        header: "Property Description",
        size: 150,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: { density: "compact" },
    enableRowNumbers: true,
    rowNumberDisplayMode: "original", // default
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportCSV(data, filename)}
          startIcon={<FileDownloadIcon />}
        >
          Export CSV
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() => handleExportExcel(data, filename)}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>
      </Box>
    ),
  });

  return (
    <Box
      my={2}
      mx="5vw"
      className="about center-main main-background-image"
      sx={{ display: { xs: "flex" }, flexDirection: "column" }}
    >
      <Box className="content" m="2vw">
        <Typography
          className="title-color"
          fontWeight={500}
          variant="h4"
          gutterBottom
        >
          Property Partners
        </Typography>
      </Box>
      <DateRangeDataFilter data={parsedData} setData={setData} />
      <Box width="100%">
        <MaterialReactTable table={table} />
      </Box>
    </Box>
  );
};

export default PropertyPartnerList;
