import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import cities from "../common/gujarat-cities.json";

const stringToBoolean = (value) => {
  if (value === "true") return true
  else if(value === "false") return false
  return undefined
}

const FranchiseRelatedInformation = ({ formData, handleChangeForm }) => {
  const [manage, setManage] = useState("");

  useEffect(() => {
    if (formData?.relationship === "yourself") {
      setManage("yourself");
    }
  }, []);

  const handleManageChange = (e) => {
    setManage(e.target.value);
    if (e.target.value === "yourself") {
      e.target.name = "relationship";
      handleChangeForm(e);
    } else {
      e.target.name = "relationship";
      e.target.value = "";
      handleChangeForm(e);
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel required id="demo-simple-select-label">City</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="city"
            value={formData?.city || ""}
            onChange={handleChangeForm}
            label="City"
          >
            {cities.map((value) => (
              <MenuItem key={value.id} value={value.name}>
                {value.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>{" "}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="state"
          name="state"
          value={formData?.state || "Gujarat"}
          // onChange={handleChangeForm}
          label="State/Province/Region"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="area-prefernce1"
          name="areaPreference1"
          value={formData?.areaPreference1 || ""}
          onChange={handleChangeForm}
          label="Area Preference 1"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="area-prefernce2"
          name="areaPreference2"
          value={formData?.areaPreference2 || ""}
          onChange={handleChangeForm}
          label="Area Preference 2"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel required id="demo-radio-buttons-group-label-1">
            Have you ever visited CHATPATA’S outlet?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label-1"
            name="isVisited"
            value={formData?.isVisited}
            onChange={(e) => handleChangeForm(e, null, stringToBoolean)}
            sx={{ flexDirection: "row", flexWrap: "wrap" }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel required id="demo-radio-buttons-group-label-5">
            Are you planning to leave your current profession?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label-5"
            name="leaveCurrentProfession"
            value={formData?.leaveCurrentProfession}
            onChange={(e) => handleChangeForm(e, null, stringToBoolean)}
            sx={{ flexDirection: "row", flexWrap: "wrap" }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel required id="demo-radio-buttons-group-label-2">
            Have you ever been a Franchise Partner for any food brand?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label-2"
            name="anyFranchisePartner"
            value={formData?.anyFranchisePartner}
            onChange={(e) => handleChangeForm(e, null, stringToBoolean)}
            sx={{ flexDirection: "row", flexWrap: "wrap" }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel required id="demo-radio-buttons-group-label-6">
            How much time you will give to store?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label-6"
            name="timeGiven"
            value={formData?.timeGiven || ""}
            onChange={handleChangeForm}
            sx={{ flexDirection: "row", flexWrap: "wrap" }}
          >
            <FormControlLabel
              value="partTime"
              control={<Radio />}
              label="Part Time"
            />
            <FormControlLabel
              value="fullTime"
              control={<Radio />}
              label="Full Time"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel required id="demo-radio-buttons-group-label-6">
            Who will manage the franchise store?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label-6"
            name="manage"
            value={manage}
            onChange={handleManageChange}
            sx={{ flexDirection: "row", flexWrap: "wrap" }}
          >
            <FormControlLabel
              value="yourself"
              control={<Radio />}
              label="Yourself"
            />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {manage === "other" && (
        <Grid item xs={12}>
          <TextField
            required
            id="other"
            name="relationship"
            value={formData?.relationship || ""}
            onChange={handleChangeForm}
            label="Relationship"
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl>
          <FormLabel required id="demo-radio-buttons-group-label-3">
            What kind of property are you looking to turn into a franchise
            store?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label-3"
            name="propertyType"
            value={formData?.propertyType || ""}
            onChange={handleChangeForm}
            sx={{ flexDirection: "row", flexWrap: "wrap" }}
          >
            <FormControlLabel
              value="rented"
              control={<Radio />}
              label="Rented"
            />
            <FormControlLabel value="owned" control={<Radio />} label="Owned" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel required id="demo-radio-buttons-group-label-4">
            What is your target opening period for the store?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label-4"
            name="periodOfOpening"
            value={formData?.periodOfOpening || ""}
            onChange={(e) => handleChangeForm(e, null, Number)}
            sx={{ flexDirection: "row", flexWrap: "wrap" }}
          >
            <FormControlLabel value="1" control={<Radio />} label="1 Months" />
            <FormControlLabel value="2" control={<Radio />} label="2 Months" />
            <FormControlLabel value="3" control={<Radio />} label="3 Months" />
            <FormControlLabel value="6" control={<Radio />} label="6 Months" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="yourself"
          name="explainYourself"
          value={formData?.explainYourself || ""}
          onChange={handleChangeForm}
          label="Please explain why you want to take the Chatpata franchise."
          fullWidth
          multiline
          rows={3}
        />
      </Grid>
    </Grid>
  );
};

export default FranchiseRelatedInformation;
