import { Box, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import "./WhyToFranchiseWithUs.css";
import { useWindowSize } from "@uidotdev/usehooks";
import { getImageLink, preloadImages } from "../common/functions";

const WhyToFranchiseWithUs = () => {
  const size = useWindowSize();
  const imageUrls = [
    getImageLink("/assets/whytofranchise/1.jpg"),
    getImageLink("/assets/whytofranchise/2.jpg"),
    getImageLink("/assets/whytofranchise/3.jpg"),
    getImageLink("/assets/whytofranchise/4.jpg"),
    getImageLink("/assets/whytofranchise/5.jpg"),
    getImageLink("/assets/whytofranchise/6.jpg"),
    getImageLink("/assets/whytofranchise/7.jpg"),
    getImageLink("/assets/whytofranchise/8.jpg"),
    getImageLink("/assets/whytofranchise/9.jpg"),
  ];
  preloadImages(imageUrls);


  const list = [
    <Box
      sx={{ display: "flex", gap: 1, alignItems: "center" }}
      item
      xs={12}
      md={6}
    >
      <img width="100px" src={getImageLink("/assets/whytofranchise/7.jpg")} alt="store" />
      <Box>
        <Typography variant="h5" className="title-color">
          Brand Recognition
        </Typography>
        <p
          style={{
            fontWeight: "bold",
            fontSize: size.width < 768 ? "14px" : "18px",
          }}
        >
          Our brand recognition can draw in regular customers and much-needed traffic when your franchise business opens.
        </p>
      </Box>
    </Box>,
    <Box
      sx={{ display: "flex", gap: 1, alignItems: "center" }}
      item
      xs={12}
      md={6}
    >
      <img width="100px" src={getImageLink("/assets/whytofranchise/1.jpg")} alt="store" />
      <Box>
        <Typography variant="h5" className="title-color">
          Flavour Fiesta
        </Typography>
        <p
          style={{
            fontWeight: "bold",
            fontSize: size.width < 768 ? "14px" : "18px",
          }}
        >
          Our menu is a winner, making customers come back for more.
        </p>
      </Box>
    </Box>,
    <Box
      sx={{ display: "flex", gap: 1, alignItems: "center" }}
      item
      xs={12}
      md={6}
    >
      <img width="100px" src={getImageLink("/assets/whytofranchise/2.jpg")} alt="store" />
      <Box>
        <Typography variant="h5" className="title-color">
          Innovation Station
        </Typography>
        <p
          style={{
            fontWeight: "bold",
            fontSize: size.width < 768 ? "14px" : "18px",
          }}
        >
          Be part of a brand that thrives on creativity, from menus to
          marketing.
        </p>
      </Box>
    </Box>,
    <Box
      sx={{ display: "flex", gap: 1, alignItems: "center" }}
      item
      xs={12}
      md={6}
    >
      <img width="100px" src={getImageLink("/assets/whytofranchise/3.jpg")} alt="store" />
      <Box>
        <Typography variant="h5" className="title-color">
          Support Squad
        </Typography>
        <p
          style={{
            fontWeight: "bold",
            fontSize: size.width < 768 ? "14px" : "18px",
          }}
        >
          Our support team is your guide, ensuring you climb the mountain of
          success with confidence.
        </p>
      </Box>
    </Box>,
    <Box
      sx={{ display: "flex", gap: 1, alignItems: "center" }}
      item
      xs={12}
      md={6}
    >
      <img width="100px" src={getImageLink("/assets/whytofranchise/8.jpg")} alt="store" />
      <Box>
        <Typography variant="h5" className="title-color">
          Reduced Risk Of Failure
        </Typography>
        <p
          style={{
            fontWeight: "bold",
            fontSize: size.width < 768 ? "14px" : "18px",
          }}
        >
          As a franchise owner, you will operate under our proven business
          model, which has consistently delivered successful results.
        </p>
      </Box>
    </Box>,
    <Box
      sx={{ display: "flex", gap: 1, alignItems: "center" }}
      item
      xs={12}
      md={6}
    >
      <img width="100px" src={getImageLink("/assets/whytofranchise/4.jpg")} alt="store" />
      <Box>
        <Typography variant="h5" className="title-color">
          Crunch the Numbers
        </Typography>
        <p
          style={{
            fontWeight: "bold",
            fontSize: size.width < 768 ? "14px" : "18px",
          }}
        >
          A tasty ROI is on the menu, with a proven track record of success.
        </p>
      </Box>
    </Box>,
    <Box
      sx={{ display: "flex", gap: 1, alignItems: "center" }}
      item
      xs={12}
      md={6}
    >
      <img width="100px" src={getImageLink("/assets/whytofranchise/5.jpg")} alt="store" />
      <Box>
        <Typography variant="h5" className="title-color">
          Cash Cow Cuisine
        </Typography>
        <p
          style={{
            fontWeight: "bold",
            fontSize: size.width < 768 ? "14px" : "18px",
          }}
        >
          We've got the recipe for turning flavour into financial gains that
          will make your wallet do a happy dance
        </p>
      </Box>
    </Box>,
    <Box
      sx={{ display: "flex", gap: 1, alignItems: "center" }}
      item
      xs={12}
      md={6}
    >
      <img width="100px" src={getImageLink("/assets/whytofranchise/9.jpg")} alt="store" />
      <Box>
        <Typography variant="h5" className="title-color">
          Being Your Own Boss
        </Typography>
        <p
          style={{
            fontWeight: "bold",
            fontSize: size.width < 768 ? "14px" : "18px",
          }}
        >
          With a franchise, it's like getting the best of both worlds—you get
          support from a successful brand, but you still get to be the boss and
          run things your way.{" "}
        </p>
      </Box>
    </Box>,
    <Box
      sx={{ display: "flex", gap: 1, alignItems: "center" }}
      item
      xs={12}
      md={6}
    >
      <img width="100px" src={getImageLink("/assets/whytofranchise/6.jpg")} alt="store" />
      <Box>
        <Typography variant="h5" className="title-color">
          Tribe of Taste
        </Typography>
        <p
          style={{
            fontWeight: "bold",
            fontSize: size.width < 768 ? "14px" : "18px",
          }}
        >
          Become part of a food-loving family of chatpata that turns every meal into a
          flavour adventure.
        </p>
      </Box>
    </Box>,
  ];
  return (
    <Box>
      <Box my="2vw">
        <Typography
          fontWeight={500}
          variant="h5"
          className="title-color"
          textAlign="center"
          gutterBottom
        >
          Why to Franchise with CHATPATA
        </Typography>

        <ul className="whyToFranchiseWithUs-list">
          {list.map((item) => (
            <ListItem component="div">
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

export default WhyToFranchiseWithUs;
